import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import {Card, CardContent, CardMedia, CircularProgress} from "@mui/material";
import {Link as LinkDom, useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {useGetTrainingModuleInstancesQuery} from "../../services/InstanceService";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import {ArrowBack, EventAvailableOutlined, LaptopMacOutlined, Today} from "@mui/icons-material";
import moment from "moment/moment";
import ExerciseProgress from "./ExerciseProgress";
import TraineeExerciseStatusChip from "./TraineeExerciseStatusChip";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TrainingModule from "../../types/TrainingModule";
import {ExerciseStatus} from "../../types/PracticalExercise";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {useGetTrainingSessionLinksQuery, useGetTrainingSessionQuery} from "../../services/TrainingSessionService";

function getTimelineColor(module: TrainingModule): string {
    if (moment(new Date()).isAfter(module.endDate)) {
        return "grey";
    } else if (moment(new Date()).isBetween(module.startDate, module.endDate)) {
        return "green"
    }
    return "#aaa"
}

type TrainingModuleTimelineProps = {
    trainingModule: TrainingModule,
    trainingSessionId: string
};

const TrainingModuleTimeline: React.FunctionComponent<TrainingModuleTimelineProps> = ({
                                                                                          trainingModule
                                                                                      }) => {
    const [module] = useState(trainingModule)
    const {
        data: traineeModulesInstances = {content: [], totalElements: 0}
    } = useGetTrainingModuleInstancesQuery({
        pageNo: 0,
        pageSize: 1000,
        trainingModuleId: module.id,
    }, {pollingInterval: 5000});

    function getDotColor(module: TrainingModule, exerciseStatus: ExerciseStatus | undefined): "primary" | "grey" | "error" | "success" {
        switch (exerciseStatus) {
            case ExerciseStatus.FINISHED:
                if (moment(new Date()).isAfter(module.endDate)) {
                    return "grey";
                }
                return "success"
            case ExerciseStatus.LIVE:
                return "error"
            case ExerciseStatus.UPCOMING:
                return "primary"
        }
        return "grey"
    }

    return (
        <>
            {
                traineeModulesInstances.content.map((instance, index) => {
                    return (
                        <TimelineItem key={"timeline-exercise-" + index}>
                            <TimelineOppositeContent
                                sx={{m: 'auto 0', marginTop: 0, paddingRight: "1.8em"}}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                <TraineeExerciseStatusChip
                                    status={instance?.practicalExercise?.status}></TraineeExerciseStatusChip>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <Grid direction="column" container
                                      justifyContent="center"
                                      alignItems="center">
                                    <Grid item>
                                        <TimelineDot
                                            color={getDotColor(module, instance.practicalExercise?.status)}/>
                                    </Grid>
                                </Grid>
                                <TimelineConnector
                                    sx={{bgcolor: getTimelineColor(module)}}/>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} container>
                                        <Grid item container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                }}>
                                                    <Typography sx={{paddingRight: 1}}
                                                                component="label">
                                                        {instance?.practicalExercise?.name}
                                                    </Typography>
                                                </div>
                                                <Typography variant="body2"
                                                            color="text.secondary">
                                                    {instance?.practicalExercise?.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <ExerciseProgress
                                            survey={instance.survey}></ExerciseProgress>
                                    </Grid>
                                    <Grid item>
                                        {instance?.practicalExercise?.status !== "UPCOMING" &&
                                            <Button component={LinkDom}
                                                    to={"/view/" + instance.id}>View</Button>}
                                    </Grid>
                                </Grid>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }</>
    )
}

const TraineeSessionsOverview: React.FunctionComponent = () => {

        const [queryParameters] = useSearchParams();


        const {
            data: trainingSession = {
                id: '', title: '', startDate: new Date(),
                endDate: new Date(),
                trainer: '',
                trainees: []
            }, isLoading: isLoadingSession
        } = useGetTrainingSessionQuery(queryParameters.get("trainingId"), {pollingInterval: 5000});

        const navigate = useNavigate();
        const {
            data: links = [],
        } = useGetTrainingSessionLinksQuery(queryParameters.get("trainingId"));

        function getModuleIcon(module: TrainingModule) {
            if (moment(new Date()).isAfter(module.endDate)) {
                return <EventAvailableOutlined/>
            } else if (moment(new Date()).isBetween(module.startDate, module.endDate)) {
                return <LaptopMacOutlined sx={{color: "white"}}/>
            } else {
                return <Today/>
            }
        }


        function getSeparatorColor(module: TrainingModule): "grey" | "success" | "primary" {
            if (moment(new Date()).isAfter(module.endDate)) {
                return "grey";
            } else if (moment(new Date()).isBetween(module.startDate, module.endDate)) {
                return "success"
            }
            return "primary"
        }

        return (
            <Grid container spacing={2}>
                <Button startIcon={<ArrowBack/>} disableElevation
                        sx={{margin: "0.5em", textDecoration: "underline"}}
                        onClick={() => navigate("/my-trainings")}>BACK</Button>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={{display: 'flex'}}>
                        <CardMedia
                            component="img"
                            sx={{width: 151}}
                            image={trainingSession.imageLink}
                            alt="Training session image"
                        />
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flex: '1 0 auto'}}>
                                <Typography component="div" variant="h5" color={"primary"}>
                                    {trainingSession.title}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {trainingSession.description}
                                </Typography>
                                {
                                    links?.length > 0 &&
                                    <Typography variant="subtitle1" sx={{fontWeight: "700"}} color="text.secondary"
                                                component="div">
                                        Useful links :
                                    </Typography>
                                }
                                {

                                    links.map((link) => {
                                        return <Grid item xs={12} md={12} lg={12}
                                                     pb={1}
                                                     key={link.id}>
                                            <Link target="_blank"
                                                  href={link?.url?.startsWith('http') ? link.url : 'https://' + link.url}
                                                  rel="noopener">
                                                {link.name}
                                            </Link>
                                        </Grid>
                                    })
                                }
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Timeline sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                            },
                        }}>
                            {
                                trainingSession?.trainingModules?.map((module, index) => {
                                    return (
                                        <React.Fragment key={"timeline-" + index}>
                                            <TimelineItem key={"timeline-module-" + index}>
                                                <TimelineOppositeContent
                                                    sx={{m: '18px 0'}}
                                                    align="right"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {moment(module.startDate).format("L LT")}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={getSeparatorColor(module)}>
                                                        {getModuleIcon(module)}
                                                    </TimelineDot>
                                                    <TimelineConnector sx={{bgcolor: getTimelineColor(module)}}/>
                                                </TimelineSeparator>
                                                <TimelineContent sx={{py: '12px', px: 2}}>
                                                    <Typography variant="h6" component="span" color={"primary"}>
                                                        {module.name}
                                                    </Typography>
                                                    <Typography>{module.description}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                            {
                                                trainingSession.id &&
                                                <TrainingModuleTimeline
                                                    trainingSessionId={trainingSession.id}
                                                    trainingModule={module}>
                                                </TrainingModuleTimeline>
                                            }
                                        </React.Fragment>)
                                })
                            }
                            {
                                isLoadingSession &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12} sx={{textAlign: "center"}}>
                                        <CircularProgress/>
                                    </Grid>
                                </Grid>
                            }
                        </Timeline>
                    </Paper>
                </Grid></Grid>
        );
    }
;

export default TraineeSessionsOverview;
