import {Chip, Tooltip} from "@mui/material";
import React from "react";
import {LocalFireDepartment, Rocket, RocketLaunch} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useCountInstancesQuery} from "../../services/InstanceService";

const InstancesCount: React.FunctionComponent = () => {

    const {
        data: startingInstancesCount = 0,
    } = useCountInstancesQuery({filter: "status=STARTING"}, {pollingInterval: 5000});
    const {
        data: startedInstancesCount = 0,
    } = useCountInstancesQuery({filter: "status=STARTED"}, {pollingInterval: 5000});
    const {
        data: failedInstancesCount = 0,
    } = useCountInstancesQuery({filter: "status=FAILED"}, {pollingInterval: 5000});

    const navigate = useNavigate();

    const handleViewInstances = (status: string) => {
        navigate('/instances?status=' + status);
    };

    return (
        <>
            {startingInstancesCount > 0 &&
                <Tooltip title="Starting instances" placement="bottom">
                    <Chip
                        label={startingInstancesCount}
                        icon={<Rocket/>}
                        variant="outlined"
                        color={"primary"}
                        sx={{marginRight: 2}}
                        onClick={() => handleViewInstances("STARTING")}
                    />
                </Tooltip>
            }
            {startedInstancesCount > 0 &&
                <Tooltip title="Started instances" placement="bottom">
                    <Chip
                        label={startedInstancesCount}
                        icon={<RocketLaunch/>}
                        variant="outlined"
                        color={"success"}
                        sx={{marginRight: 2}}
                        onClick={() => handleViewInstances("STARTED")}
                    />
                </Tooltip>
            }
            {failedInstancesCount > 0 &&
                <Tooltip title="Failed instances" placement="bottom">
                    <Chip
                        label={failedInstancesCount}
                        icon={<LocalFireDepartment/>}
                        variant="outlined"
                        color="error"
                        sx={{marginRight: 2}}
                        onClick={() => handleViewInstances("FAILED")}
                    />
                </Tooltip>
            }
        </>
    );
}

export default InstancesCount;
