import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HeaderLoggedMenu from "./HeaderLoggedMenu";
import {useKeycloak} from "@react-keycloak/web";
import lightModeLogo from '../../assets/logo_lupise.png';
import darkModeLogo from '../../assets/logo_lupise_darkmode.png';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../slices";
import ListItemButton from "@mui/material/ListItemButton";
import {ListSubheader, Tooltip, useMediaQuery} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Link as DOMLink, useLocation} from "react-router-dom";

import {
    Article,
    BugReport,
    Close,
    Computer,
    GitHub,
    LibraryBooks,
    MenuBook,
    School,
    SchoolOutlined
} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import HeaderUnloggedMenu from "./HeaderUnloggedMenu";
import InstancesCount from "./InstancesCount";
import {setDarkTheme, setLightTheme, toggleTheme} from "../../slices/themeSlice";
import {MaterialUISwitch} from "../utils/Utils";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

type Props = {
    pageTitle: string;
};

const Header: React.FunctionComponent<Props> = ({pageTitle}) => {
    const [open, setOpen] = React.useState(true);
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const location = useLocation();
    const theme = useTheme();

    const {keycloak} = useKeycloak();

    const activeRoute = (routeName: string) => {
        return location.pathname.startsWith(routeName);
    }

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const dispatch = useDispatch();
    const darkMode = useSelector((state: RootState) => state.theme.darkMode);
    const osDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const localStorageTheme = localStorage.getItem("theme");
    const isDarkMode = localStorageTheme === "dark";

    React.useEffect(() => {
        if (localStorageTheme !== "dark" && localStorageTheme !== "light") {
            osDarkMode ? dispatch(setDarkTheme()) : dispatch(setLightTheme())
        }
    }, [osDarkMode, localStorageTheme]);


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open} color={"secondary"}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        {pageTitle}
                    </Typography>
                    {isAdmin &&
                        <InstancesCount/>
                    }
                    <Tooltip title={darkMode ? "Disable dark mode" : "Enable dark mode"}>
                        <MaterialUISwitch defaultChecked={isDarkMode} onClick={() => {
                            dispatch(toggleTheme())

                        }}></MaterialUISwitch>
                    </Tooltip>
                    {keycloak.authenticated &&
                        <HeaderLoggedMenu/>
                    }
                    {!keycloak.authenticated &&
                        <HeaderUnloggedMenu/>
                    }
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                    '.MuiListSubheader-sticky': {
                        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                    },
                }
            }}>
                <DrawerHeader>
                    <IconButton onClick={toggleDrawer}>
                        <Close/>
                    </IconButton>
                </DrawerHeader>
                <List component="nav"
                      sx={{
                          '&& .Mui-selected, && .Mui-selected:hover': {
                              '&, & .MuiListItemIcon-root, & .MuiListItemText': {
                                  color: theme.palette.primary.main,
                              },
                          }
                      }}>
                    {isAdmin ?
                        <React.Fragment>
                            <ListItemButton selected={activeRoute("/home")} component={DOMLink} to="/home">
                                <Tooltip title='Home' placement="right">
                                    <ListItemIcon>
                                        <HomeIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Home"/>
                            </ListItemButton>
                            <ListItemButton component={DOMLink} selected={activeRoute("/session")} to="/sessions">
                                <Tooltip title='Training sessions' placement="right">
                                    <ListItemIcon>
                                        <School/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Training sessions"/>
                            </ListItemButton>
                            <ListItemButton component={DOMLink} selected={activeRoute("/instances")} to="/instances">
                                <Tooltip title='Instances' placement="right">
                                    <ListItemIcon>
                                        <Computer/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Instances"/>
                            </ListItemButton>
                            <ListSubheader component="div"
                                           sx={{paddingLeft: "17px", fontSize: "0.9rem", fontWeight: "700"}} inset
                                           hidden={!open}> Catalog </ListSubheader>
                            <ListItemButton component={DOMLink} selected={activeRoute("/learningPaths")}
                                            to="/learningPaths">
                                <Tooltip title='Learning paths' placement="right">
                                    <ListItemIcon>
                                        <MenuBook/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Learning paths"/>
                            </ListItemButton>
                            <ListItemButton component={DOMLink} selected={activeRoute("/modules")} to="/modules">
                                <Tooltip title='Modules' placement="right">
                                    <ListItemIcon>
                                        <LibraryBooks/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Modules"/>
                            </ListItemButton>
                            <ListItemButton component={DOMLink} selected={activeRoute("/exercises")} to="/exercises">
                                <Tooltip title='Practical exercises' placement="right">
                                    <ListItemIcon>
                                        <Article/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Practical exercises"/>
                            </ListItemButton>
                            <ListSubheader component="div"
                                           sx={{paddingLeft: "17px", fontSize: "0.9rem", fontWeight: "700"}} inset
                                           hidden={!open}> Administration </ListSubheader>
                            <ListItemButton component={DOMLink} selected={activeRoute("/events")} to="/events">
                                <Tooltip title='Logbook' placement="right">
                                    <ListItemIcon>
                                        <AutoStoriesIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Logbook"/>
                            </ListItemButton>
                            <ListItemButton component={DOMLink} selected={activeRoute("/versions")} to="/versions">
                                <Tooltip title='Version' placement="right">
                                    <ListItemIcon>
                                        <GitHub/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Versions"/>
                            </ListItemButton>
                            <ListItemButton target="_blank"
                                            href="https://lupise.atlassian.net/jira/software/projects/CAP/issues/">
                                <Tooltip title='Report a bug' placement="right">
                                    <ListItemIcon>
                                        <BugReport/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Report a bug"/>
                            </ListItemButton>
                        </React.Fragment>

                        :
                        <React.Fragment>
                            <ListItemButton component={DOMLink} selected={activeRoute("/home")} to="/home">
                                <Tooltip title='Home' placement="right">
                                    <ListItemIcon>
                                        <HomeIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Home"/>

                            </ListItemButton>
                            <ListItemButton component={DOMLink}
                                            selected={activeRoute("/view") || activeRoute("/my-sessions") || activeRoute("/my-training")}
                                            to="/my-trainings">
                                <Tooltip title='My Trainings' placement="right">
                                    <ListItemIcon>
                                        <SchoolOutlined/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="My Trainings"/>
                            </ListItemButton>
                        </React.Fragment>
                    }
                </List>
                <div style={{
                    position: "fixed",
                    bottom: 0,
                    textAlign: "center",
                    paddingBottom: 5,
                    width: "inherit"
                }}>
                    <img style={{width: "80%"}} alt="Logo"
                         src={theme.palette.mode === 'light' ? lightModeLogo : darkModeLogo}></img>
                </div>

            </Drawer>
        </Box>
    );
}

export default Header;