import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Chip, LinearProgress} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridLogicOperator,
    GridPaginationModel,
    GridSortDirection,
    GridSortModel,
    GridValueFormatterParams
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {useNavigate, useSearchParams} from "react-router-dom";
import NewPracticalExerciseCatalog from "./NewPracticalExerciseCatalog";
import Grid from "@mui/material/Grid";
import {SignalCellularAlt} from "@mui/icons-material";
import {
    useDeletePracticalExerciseMutation,
    useLazyGetPracticalExercisesQuery,
    useLazyGetTrainingModulesQuery
} from "../../services/CatalogService";
import {stringFilterOperators} from "../utils/Utils";
import PracticalExercise from "../../types/PracticalExercise";
import SnackBarUtils from "../SnackBarUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TrainingModule from "../../types/TrainingModule";

type Props = {};

function getToggleColor(level: string) {
    switch (level) {
        case "EASY" :
            return "success";
        case "MEDIUM" :
            return "warning";
        case "HARD" :
            return "error";
        default:
            return "info"
    }
}

const PracticalExerciseCatalogList: React.FunctionComponent<Props> = () => {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 20,
        page: 0,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageSize, setPageSize] = React.useState(20);
    const [pageNo, setPageNo] = React.useState(0);
    const [sortOptions, setSortOptions] = React.useState(["name,asc"]);
    const [filterOptions, setFilterOptions] = React.useState([""]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openTrainingModulesOnDeleteDialog, setOpenTrainingModulesOnDeleteDialog] = React.useState(false);

    const [selectedPracticalExercise, setSelectedPracticalExercise] = useState<PracticalExercise>();
    const [selectedPracticalExerciseModules, setSelectedPracticalExerciseModules] = useState<TrainingModule[]>([]);
    const [deletePracticalExercise] = useDeletePracticalExerciseMutation();

    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: "startDate", sort: "desc"
    }]);

    const [triggerGetExercises, {
        data: exercisesPage = {content: [], totalElements: 0},
        isFetching
    }] = useLazyGetPracticalExercisesQuery();
    const navigate = useNavigate();

    const getExercisesPage = () => {
        triggerGetExercises({
            pageNo: pageNo,
            pageSize: pageSize,
            sort: sortOptions,
            filter: filterOptions
        })
    }

    const [triggerGetTrainingModules, {
        data: trainingsPage = {content: [], totalElements: 0},
    }] = useLazyGetTrainingModulesQuery();


    const [rowCountState, setRowCountState] = React.useState(exercisesPage.totalElements);

    useEffect(() => {
        let name = searchParams.get("name");
        if (name) {
            handleFilterModelChange({
                items: [{id: 1, field: 'name', operator: 'contains', value: name}],
                logicOperator: GridLogicOperator.And, quickFilterValues: []
            });
        }
        let sort = searchParams.get("sort");
        if (sort) {
            handleSortModelChange([{field: sort.split(",")[0], sort: sort.split(",")[1] as GridSortDirection}]);
        }
        let pageParam = searchParams.get("page");
        let pageSizeParam = searchParams.get("pageSize");
        handlePaginationChange({
            pageSize: pageSizeParam ? parseInt(pageSizeParam) : 20,
            page: pageParam ? parseInt(pageParam) : 0
        });
    }, [searchParams]);

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            exercisesPage.totalElements !== undefined ? exercisesPage.totalElements : prevRowCountState,
        );
    }, [exercisesPage.totalElements, setRowCountState]);

    React.useEffect(() => {
        getExercisesPage();
    }, [pageNo, pageSize, sortOptions, filterOptions]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel(sortModel)
        let firstSort = sortModel.find(first => first);
        if (firstSort) {
            setSearchParams(searchParams => {
                searchParams.set("sort", firstSort?.field + "," + firstSort?.sort)
                return searchParams;
            });
        }

        // Here you save the data you need from the sort model
        setSortOptions(sortModel.map((sortItem) => {
            return sortItem.field + "," + sortItem.sort
        }));
    }, []);

    const handleFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        setFilterModel(filterModel)
        setFilterOptions(filterModel.items.map((filterItem) => {
            return filterItem.value ? filterItem.field + "=" + filterItem.value : ""
        }));
        filterModel.items.forEach((item) => {
            item.value && setSearchParams(searchParams => {
                searchParams.set(item.field, item.value)
                return searchParams;
            });
        })
    }, []);

    const handleClickOpenDeleteDialog = (practicalExercise: PracticalExercise) => {
        setSelectedPracticalExercise(practicalExercise);
        triggerGetTrainingModules({
            pageNo: 0,
            pageSize: 20,
            filter: ["exerciseId=" + practicalExercise.id],
        }).then((resp) => {
            let trainingModuleList = resp.data?.content;
            if (trainingModuleList !== undefined && trainingModuleList.length > 0) {
                setSelectedPracticalExerciseModules(trainingModuleList);
                setOpenTrainingModulesOnDeleteDialog(true);
            } else {
                setOpenDeleteDialog(true);
            }
        });
    };

    const handleCloseTrainingModulesOnDeleteDialog = () => {
        setSelectedPracticalExerciseModules([]);
        setSelectedPracticalExercise({});
        setOpenTrainingModulesOnDeleteDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setSelectedPracticalExercise({});
        setOpenDeleteDialog(false);
    };

    const handleValidDeleteDialog = () => {
        if (selectedPracticalExercise?.id) {
            deletePracticalExercise(selectedPracticalExercise.id).unwrap().then(() => {
                SnackBarUtils.info('Exercise deleted');
            }).catch(() => {
                SnackBarUtils.error('Error happened while deleting exercise.');
            });
        }
        setOpenDeleteDialog(false);
    };

    const handlePaginationChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
        setPageSize(newPaginationModel.pageSize);
        setPageNo(newPaginationModel.page)
        // Here you save the data you need from the sort model

        setSearchParams(searchParams => {
            searchParams.set("page", newPaginationModel.page.toString())
            searchParams.set("pageSize", newPaginationModel.pageSize.toString())
            return searchParams;
        });
    }, []);
    const columns: GridColDef[] = [
        {
            field: 'name',
            align: 'center',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'description',
            align: 'center',
            headerName: 'Description',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'scriptId',
            align: 'center',
            headerName: 'Script',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                //replace cyberacademy--exercises-- prefix if at the beginning
                return params.value.replace(/^(cyberacademy--exercises--)/, "");
            },
            headerAlign: 'center',
            filterable: false,
            sortable: false
        },
        {
            field: 'level',
            align: 'center',
            headerName: 'Level',
            flex: 1,
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Chip variant="outlined" color={getToggleColor(params.value)}
                      label={params.value}
                      sx={{border: "none", fontWeight: "700"}} icon={<SignalCellularAlt/>}/>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            width: 250,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Button variant="text">Edit</Button>
                    <Button variant="text" onClick={(e) => {
                        handleClickOpenDeleteDialog(params.row);
                        e.stopPropagation();
                    }
                    }
                            color={"error"}>Delete</Button>
                </>
            )
        }
    ];

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom mb={3}>
                            Practical Exercises
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'right'}}>
                        <NewPracticalExerciseCatalog></NewPracticalExerciseCatalog>
                    </Grid>
                </Grid>
                <DataGrid
                    sx={{
                        boxShadow: 0,
                        border: 0,
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none",
                        },
                        cursor: "pointer"
                    }}
                    disableRowSelectionOnClick
                    autoHeight
                    rows={exercisesPage.content}
                    columns={columns}
                    pageSizeOptions={[10, 20, 50]}
                    paginationMode={"server"}
                    rowCount={rowCountState}
                    filterMode="server"
                    onFilterModelChange={handleFilterModelChange}
                    pagination
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    getRowId={(row: any) => row.id.toString()}
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                    filterModel={filterModel}
                    loading={isFetching}
                    onRowClick={(row) => navigate("/exercises/" + row.id)}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                />
            </Paper>
            <Dialog open={openDeleteDialog}>
                <DialogTitle>Delete practical exercise?</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this exercise?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleCloseDeleteDialog}>CANCEL</Button>
                    <Button disableElevation color={"error"} onClick={handleValidDeleteDialog}>DELETE</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openTrainingModulesOnDeleteDialog}>
                <DialogTitle>Training modules linked</DialogTitle>
                <DialogContent>
                    <p>You can't delete this exercise because it is part of the following modules:</p>
                    <ul>
                        {selectedPracticalExerciseModules.map(module => {
                            return (<li>{module.name}</li>);
                        })
                        }
                    </ul>
                    <p>Please remove the exercise from these modules first.</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleCloseTrainingModulesOnDeleteDialog}>CLOSE</Button>
                </DialogActions>
            </Dialog>
        </>);
}

export default PracticalExerciseCatalogList;
