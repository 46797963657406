import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import {CardContent, CardHeader, Chip, Drawer, Fab} from "@mui/material";
import List from "@mui/material/List";
import InstanceOutputForm from "../instance/InstanceOutputForm";
import {useGetInstanceGuidelineQuery, useGetInstanceQuery} from "../../services/InstanceService";
import Typography from "@mui/material/Typography";
import {AnswerQuestion} from "./AnswerQuestion";
import TraineeExerciseStatusChip from "./TraineeExerciseStatusChip";
import {Item, StyledGuidelineViewer, StyledRating} from "../utils/Utils";
import {AccessTime, ArrowBack, SignalCellularAlt} from "@mui/icons-material";
import ListIcon from '@mui/icons-material/List';
import Rating from "../../types/Rating";
import TextField from "@mui/material/TextField";
import SnackBarUtils from "../SnackBarUtils";
import {useRateExerciseMutation} from "../../services/TrainingSessionService";
import LoadingButton from "@mui/lab/LoadingButton";
import {Status} from "../../types/Instance";
import Button from "@mui/material/Button";


type Props = {
    pageTitle: (arg: string) => void
};

const TraineeView: React.FunctionComponent<Props> = ({pageTitle}) => {

    const {instanceId} = useParams<{ instanceId: any }>()
    const {
        data: instance = {id: '', trainee: ''},
    } = useGetInstanceQuery(instanceId, {pollingInterval: 5000});
    const {
        data: guideline,
    } = useGetInstanceGuidelineQuery(instanceId);
    const [formattedGuideline, setFormattedGuideline] = React.useState<string | undefined>("");

    const [rating, setRating] = React.useState<Rating>({id: "", note: 0, comment: ""});

    useEffect(() => {
        setFormattedGuideline(guideline)
        if (instance.status === Status.STARTED) {
            if (instance.outputs) {
                let guidelineToFormat = guideline;
                Object.keys(instance.outputs).forEach(key => {
                    if (instance.practicalExercise && instance?.outputs && instance?.outputs[key]) {
                        let toReplace = '#' + key + '#'
                        let replaceWith = instance.outputs[key];
                        guidelineToFormat = guidelineToFormat?.replaceAll(toReplace, replaceWith);
                    }
                })
                setFormattedGuideline(guidelineToFormat)
            }
        }
    }, [guideline, instance]);

    useEffect(() => {
        if (instance.practicalExercise?.name) {
            pageTitle(instance.practicalExercise?.name);
        }
    }, [pageTitle, instanceId, instance]);

    function getToggleColor(level?: string) {
        switch (level) {
            case "EASY" :
                return "success";
            case "MEDIUM" :
                return "warning";
            case "HARD" :
                return "error";
            default:
                return "info"
        }
    }

    const [rateExercise, {isLoading: isRating}] = useRateExerciseMutation();
    const navigate = useNavigate();

    function handleSubmitRating() {
        rateExercise({
            exerciseId: instance?.practicalExercise?.id,
            rating: rating
        }).unwrap().then((resp: Rating) => {
            setRating({id: "", note: 0, comment: ""});
            SnackBarUtils.info('Your review has been submitted, thank you !')
        }, (err) => {
            if (err.status === 409) {
                SnackBarUtils.error('You already submitted your review for this exercise')
            } else {
                SnackBarUtils.error('Oops! Error happened while submitting your review... Please retry later!')
            }
        });
    }

    const [showData, setShowData] = React.useState(false);

    const toggleDrawer = () =>
        () => {
            setShowData(!showData);
        };

    return (
        <Grid container spacing={2}>
            <Button startIcon={<ArrowBack/>} disableElevation
                    sx={{margin: "0.5em", textDecoration: "underline"}}
                    onClick={() => navigate("/my-sessions?trainingId=" + instance?.practicalExercise?.trainingModule?.trainingSession?.id)}>BACK</Button>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={instance.practicalExercise &&
                        <Grid container alignItems={"center"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography mr={2} component="h2" variant="h5" color="primary"
                                                sx={{display: 'inline'}}>
                                        {instance.practicalExercise?.name}
                                    </Typography>
                                    <TraineeExerciseStatusChip
                                        status={instance?.practicalExercise?.status}></TraineeExerciseStatusChip>
                                </Grid>
                            </Grid>
                        </Grid>
                    }/>
                    <CardContent sx={{paddingY: 0}}>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={2} md={12} lg={12}><Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <Typography mr={1} component="h2" variant="h6"
                                                sx={{display: 'inline'}}>
                                        Exercise
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {
                                        instance.practicalExercise?.exerciseDuration ?
                                            <Chip sx={{border: "none", fontWeight: "700"}}
                                                  icon={<AccessTime color={"primary"}/>}
                                                  label={instance.practicalExercise?.exerciseDuration + " min"}
                                                  variant="outlined"/>
                                            : ""
                                    }
                                    {
                                        instance.practicalExercise?.level &&
                                        <Chip variant="outlined"
                                              color={getToggleColor(instance?.practicalExercise?.level)}
                                              label={instance?.practicalExercise?.level}
                                              sx={{border: "none", fontWeight: "700"}} icon={<SignalCellularAlt/>}/>
                                    }

                                </Grid>
                            </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            {instance.practicalExercise?.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Guideline
                        </Typography>
                    }/>
                    <CardContent sx={{overflow: "auto", paddingY: 0}}>
                        {
                            formattedGuideline ? formattedGuideline.split("???QUESTION???").map((guidelinePart, index) => {
                                let answer = instance?.survey?.find((survey) => survey.sequence === index);
                                if (answer) {
                                    return (<React.Fragment key={"question-part-" + index}>
                                        <StyledGuidelineViewer
                                            disallowedElements={['script', 'iframe']}
                                            style={{
                                                marginTop: 10, marginBottom: 10
                                            }}
                                            source={guidelinePart}/>
                                        <AnswerQuestion key={"question-" + index} answer={answer}
                                                        instanceId={instance.id}></AnswerQuestion>
                                    </React.Fragment>)
                                } else return (
                                    <StyledGuidelineViewer key={"question-part-" + index}
                                                           disallowedElements={['script', 'iframe']}
                                                           style={{
                                                               marginTop: 10, marginBottom: 10
                                                           }}
                                                           source={guidelinePart}/>)
                            }) : "Guideline is unavailable yet. Please wait for the trainer to start the exercise."
                        }
                    </CardContent>
                </Item>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Rate this exercise!
                        </Typography>
                    }/>
                    <CardContent sx={{overflow: "auto", paddingY: 0}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                Help us improving this exercise by rating and comment it!
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>

                                <StyledRating
                                    name="exercise-note"
                                    value={rating?.note}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setRating((oldState: Rating) => ({
                                                ...oldState, note: newValue
                                            }));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    id="exercise-comment"
                                    label="Share us your experience"
                                    multiline
                                    rows={3}
                                    value={rating?.comment}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setRating((oldState: Rating) => ({
                                            ...oldState, comment: event.target.value
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <LoadingButton sx={{marginTop: 2}}
                                               size="small"
                                               disabled={rating.note === 0}
                                               onClick={handleSubmitRating}
                                               loading={isRating}
                                               variant="contained" disableElevation
                                >
                                    <span>SUBMIT</span>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
            <React.Fragment key={"data-drawer"}>
                <Fab variant="extended" color={"primary"} sx={{
                    margin: 0,
                    top: "auto",
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                }} onClick={toggleDrawer()}>
                    <ListIcon sx={{mr: 1}}/>
                    Show exercise data
                </Fab>

                <Drawer
                    anchor={"bottom"}
                    open={showData}
                    onClose={toggleDrawer()}
                >
                    <Grid item xs={12} md={12} lg={12}>
                        <Item>
                            <CardHeader sx={{paddingBottom: "0px"}} title={
                                <Typography component="h2" variant="h6" color="primary">
                                    Data
                                </Typography>
                            }/>
                            <CardContent>
                                <List>
                                    {
                                        instance.outputs && Object.keys(instance.outputs).length > 0 ? Object.keys(instance.outputs).map(key =>
                                            <InstanceOutputForm key={key} label={key}
                                                                value={instance?.outputs ? [key] ? instance.outputs[key] : "" : ""}/>
                                        ) : <>No data available. Please wait for the trainer to start the exercise.</>
                                    }
                                </List>
                            </CardContent>
                        </Item>
                    </Grid>
                </Drawer>
            </React.Fragment>

        </Grid>
    );
};

export default TraineeView;