import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import {LinearProgress} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridPaginationModel,
    GridSortDirection,
    GridSortModel
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import NewLearningPathCatalog from "./NewLearningPathCatalog";
import {stringFilterOperators} from "../../utils/Utils";
import {useLazyGetLearningPathsQuery} from "../../../services/CatalogService";

type Props = {};

const LearningPathCatalogList: React.FunctionComponent<Props> = () => {

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 20,
        page: 0,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageSize, setPageSize] = React.useState(20);
    const [pageNo, setPageNo] = React.useState(0);
    const [sortOptions, setSortOptions] = React.useState(["name,asc"]);
    const [filterOptions, setFilterOptions] = React.useState([""]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [triggerGetLearningPaths, {
        data: learningPathsPage = {content: [], totalElements: 0},
        isFetching
    }] = useLazyGetLearningPathsQuery();
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: "name", sort: "asc"
    }]);
    const navigate = useNavigate();

    const getLearningPathsPage = () => {
        triggerGetLearningPaths({
            pageNo: pageNo,
            pageSize: pageSize,
            sort: sortOptions,
            filter: filterOptions
        })
    }

    useEffect(() => {
        let name = searchParams.get("name");
        if (name) {
            handleFilterModelChange({items: [{id: 1, field: 'name', operator: 'contains', value: name}]});
        }
        let description = searchParams.get("description");
        if (description) {
            handleFilterModelChange({items: [{id: 1, field: 'description', operator: 'contains', value: description}]});
        }
        let sort = searchParams.get("sort");
        if (sort) {
            handleSortModelChange([{field: sort.split(",")[0], sort: sort.split(",")[1] as GridSortDirection}]);
        }
        let pageParam = searchParams.get("page");
        let pageSizeParam = searchParams.get("pageSize");
        handlePaginationChange({
            pageSize: pageSizeParam ? parseInt(pageSizeParam) : 20,
            page: pageParam ? parseInt(pageParam) : 0
        });
    }, [searchParams]);

    const [rowCountState, setRowCountState] = React.useState(learningPathsPage.totalElements);
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            learningPathsPage.totalElements !== undefined ? learningPathsPage.totalElements : prevRowCountState,
        );
    }, [learningPathsPage.totalElements, setRowCountState]);

    React.useEffect(() => {
        getLearningPathsPage();
    }, [pageNo, pageSize, sortOptions, filterOptions]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel(sortModel)
        let firstSort = sortModel.find(first => first);
        if (firstSort) {
            setSearchParams(searchParams => {
                searchParams.set("sort", firstSort?.field + "," + firstSort?.sort)
                return searchParams;
            });
        }

        // Here you save the data you need from the sort model
        setSortOptions(sortModel.map((sortItem) => {
            return sortItem.field + "," + sortItem.sort
        }));
    }, []);

    const handleFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        setFilterModel(filterModel)
        // Here you save the data you need from the sort model
        setFilterOptions(filterModel.items.map((filterItem) => {
            return filterItem.value ? filterItem.field + "=" + filterItem.value : ""
        }));
        filterModel.items.forEach((item) => {
            item.value && setSearchParams(searchParams => {
                searchParams.set(item.field, item.value)
                return searchParams;
            });
        })
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'name',
            align: 'center',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'description',
            align: 'center',
            headerName: 'Description',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            width: 250,
            renderCell: () => (
                <Button variant="text">Edit</Button>
            ),
            filterable: false,
            sortable: false
        }
    ];
    const handlePaginationChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
        setPageSize(newPaginationModel.pageSize);
        setPageNo(newPaginationModel.page)
        // Here you save the data you need from the sort model

        setSearchParams(searchParams => {
            searchParams.set("page", newPaginationModel.page.toString())
            searchParams.set("pageSize", newPaginationModel.pageSize.toString())
            return searchParams;
        });
    }, []);

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom mb={3}>
                            Learning paths
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'right'}}>
                        <NewLearningPathCatalog></NewLearningPathCatalog>
                    </Grid>
                </Grid>
                <DataGrid
                    sx={{
                        boxShadow: 0,
                        border: 0,
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none",
                        },
                        cursor: "pointer"
                    }}
                    sortModel={sortModel}
                    disableRowSelectionOnClick
                    autoHeight
                    rows={learningPathsPage.content}
                    columns={columns}
                    pageSizeOptions={[10, 20, 50]}
                    paginationMode={"server"}
                    rowCount={rowCountState}
                    filterMode="server"
                    onFilterModelChange={handleFilterModelChange}
                    filterModel={filterModel}
                    getRowId={(row: any) => row.id.toString()}
                    pagination
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    loading={isFetching}
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                    onRowClick={(row) => navigate("/learningPaths/" + row.id)}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                />
            </Paper>
        </>);
}

export default LearningPathCatalogList;
